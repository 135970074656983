import React, { useEffect, useState } from 'react';
import { useChat } from '../../context/ChatContext';
import { Alert } from '../Alert';
import { useAlert } from '../../hooks/useAlert';
import { useApi } from '../../utils/api';
import { render } from '@testing-library/react';
import { useNavigate } from 'react-router-dom';

export const ThreadSelectList = () => {
	const { alert, handleSetAlert } = useAlert();
	const api = useApi();
	const navigate = useNavigate();

	const { availableThreads, setAvailableThreads, setActiveThread, activeThread, setChatMessages, threadSelectorOpen, setThreadSelectorOpen } =
		useChat();

	/* Set state for threadSelectorOpen */
	//const [threadSelectorOpen, setThreadSelectorOpen] = useState(false);

	useEffect(() => {
		// Define an async function to fetch data
		const fetchData = async () => {
			try {
				const response = await api.get(`/chat/getAvailableThreads`);
				if (response && response.data) {
					setAvailableThreads(response.data);
				}
			} catch (error) {
				error && error.response && error.response.data && handleSetAlert({ ...error.response.data, timeout: 5000 });
			} finally {
			}
		};

		fetchData(); // Call the async function
	}, []); // Empty dependency array ensures this runs only once when the component mounts

	const formatUpdatedDate = (dateString) => {
		const date = new Date(dateString);

		const formatter = new Intl.DateTimeFormat('sv-SE', {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		});

		let formattedDate = formatter.format(date);
		return formattedDate;
	};

	const formatLatestPostDate = (dateString) => {
		const date = new Date(dateString);

		const formatter = new Intl.DateTimeFormat('sv-SE', {
			weekday: 'long',
			day: 'numeric',
			year: 'numeric',
			month: 'long',
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
			timeZone: 'Europe/Stockholm', // Adjust for local Swedish time
		});

		let formattedDate = formatter.format(date);
		return formattedDate;
	};

	const renderAvailableThreads = () => {
		/* Get an array with all the forums */
		let threadsByForum = {};
		for (let thread of availableThreads) {
			if (!threadsByForum[thread.forum]) {
				threadsByForum[thread.forum] = [];
			}
			threadsByForum[thread.forum].push(thread);
		}

		/* Sort the forums alphabetically */
		threadsByForum = Object.fromEntries(Object.entries(threadsByForum).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)));

		/* Sort the threads by the last post date */
		Object.keys(threadsByForum).map((forum, index) => {
			threadsByForum[forum].sort((a, b) => new Date(b.latest_post_date).getTime() - new Date(a.latest_post_date).getTime());
		});

		/* Render the threads */
		return Object.keys(threadsByForum).map((forum, index) => {
			return (
				<table className="fb-table mb-4" key={`forum-${forum}`}>
					<caption className="thread-selector-forum">{forum}</caption>
					<tbody>
						{threadsByForum[forum].map((thread, index) => {
							return (
								<tr key={`thread-${thread.id}`}>
									<td className="thread-title-col col-9">
										<a
											href="#"
											className="thread-selector-item text-decoration-none"
											onClick={(event) => handleThreadClick(event, thread)}>
											{thread.title}
										</a>
										<p className="text-muted text-xs mb-0">Senaste inlägg: {formatLatestPostDate(thread.latest_post_date)}</p>
									</td>
									<td className="text-end col-3">
										<p className="mb-0">
											<strong className="text-xs">{thread.number_of_posts} svar</strong>
										</p>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			);
		});
	};

	const handleThreadClick = (event, thread) => {
		event.preventDefault();
		setActiveThread(thread);
		navigate('/chat');
	};

	return (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12 col-md-12 col-lg-8 offset-lg-2">{renderAvailableThreads()}</div>
			</div>
		</div>
	);
};
