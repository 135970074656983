import { Link } from 'react-router-dom';
import { useApp } from './../context/AppContext';
import logo from './../assets/logo.png';

export const Navbar = ({}) => {
	//const [isActive, setIsActive] = useState(true);
	const { isAuthenticated, signOut, isAdmin, user, mobileMenuOpen, setMobileMenuOpen } = useApp();

	const getFirstLetter = () => {
		if (user && user.email) {
			return user.email.charAt(0).toUpperCase();
		}
		return '';
	};

	const HamburgerIcon = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round">
			<line x1="3" y1="6" x2="21" y2="6" />
			<line x1="3" y1="12" x2="21" y2="12" />
			<line x1="3" y1="18" x2="21" y2="18" />
		</svg>
	);

	const CloseIcon = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round">
			<line x1="18" y1="6" x2="6" y2="18" />
			<line x1="6" y1="6" x2="18" y2="18" />
		</svg>
	);

	return (
		<nav className="site-navbar">
			<div className="container">
				<div className="row">
					<div className="col-12 col-md-12 col-lg-8 offset-lg-2">
						<div className="mobile-menu-controls">
							<div className="logo">
								<Link onClick={() => setMobileMenuOpen(false)} to={isAuthenticated ? '/' : '/signin'}>
									<img src={logo} alt="Flashback GPT" />
									<p>Flashback GPT</p>
								</Link>
							</div>
							<button className="mobile-menu-button" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
								{!mobileMenuOpen && HamburgerIcon()}
								{mobileMenuOpen && CloseIcon()}
							</button>
						</div>
						<div className={`nav-menu-wrapper ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
							<ul className="nav-menu">
								<li className="logo">
									<Link onClick={() => setMobileMenuOpen(false)} to={isAuthenticated ? '/' : '/signin'}>
										<img src={logo} alt="Flashback GPT" />
										<p>Flashback GPT</p>
									</Link>
								</li>
								{isAuthenticated && (
									<li>
										<Link onClick={() => setMobileMenuOpen(false)} to="/chat">
											Chatta
										</Link>
									</li>
								)}
								{isAdmin() && (
									<li>
										<Link onClick={() => setMobileMenuOpen(false)} to="/admin">
											Admin
										</Link>
									</li>
								)}
								{!isAuthenticated && (
									<li>
										<Link onClick={() => setMobileMenuOpen(false)} to="/signup">
											Skapa konto
										</Link>
									</li>
								)}
								{!isAuthenticated && (
									<li>
										<Link onClick={() => setMobileMenuOpen(false)} to="/signin">
											Logga in
										</Link>
									</li>
								)}
								{isAuthenticated && (
									<li>
										<a href="#" onClick={() => signOut()}>
											Logga ut
										</a>
									</li>
								)}
								{isAuthenticated && (
									<li>
										<Link onClick={() => setMobileMenuOpen(false)} to="/profile">
											<span className="profile-letter">{getFirstLetter()}</span>
										</Link>
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};
